export const allCountryList = [
  {
    name: "Cambodia",
    value: "KH",
    phn_code: "+855",
  },
  {
    name: "China",
    value: "CN",
    phn_code: "+86",
  },
  {
    name: "India",
    value: "IN",
    phn_code: "+91",
  },
  {
    name: "Nepal",
    value: "NP",
    phn_code: "+977",
  },
  {
    name: "Vietnam",
    value: "VN",
    phn_code: "+84",
  },
];

export const availableCountires = [
  "Algeria",
  "American Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahrain",
  "Bangladesh",
  "Belgium",
  "Belize",
  "Bermuda",
  "Bhutan",
  "Bolivia",
  "Botswana",
  "Brazil",
  "British Indian Ocean Territory",
  "Brunei Darussalam",
  "Bulgaria",
  "Cabo Verde",
  "Cameroon",
  "Canada",
  "Caribbean Netherlands",
  "Chile",
  "China",
  "Christmas Island",
  "Cocos (Keeling) Islands",
  "Colombia",
  "Costa Rica",
  "Croatia",
  "Curaçao",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Estonia",
  "Falkland Islands",
  "Faroe Islands",
  "Federated States of Micronesia",
  "Federated States of Micronesia",
  "Fiji",
  "Finland",
  "France",
  "French Guiana",
  "French Polynesia",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guyana",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Ireland",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Kuwait",
  "Latvia",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Malawi",
  "Malaysia",
  "Malta",
  "Martinique",
  "Mauritania",
  "Mayotte",
  "Mexico",
  "Monaco",
  "Montserrat",
  "Morocco",
  "Nepal",
  "Netherlands",
  "New Zealand",
  "Niger",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "Northern Mariana Islands",
  "Norway",
  "Oman",
  "Peru",
  "Philippines",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Romania",
  "Saudi Arabia",
  "Singapore",
  "Slovak Republic",
  "Slovenia",
  "Solomon Islands",
  "South Africa",
  "South Korea",
  "Spain",
  "Sri lanka",
  "Sweden",
  "Switzerland",
  "Taiwan",
  "Thailand",
  "Tonga",
  "Turkey",
  "Turkmenistan",
  "Tuvalu",
  "UAE",
  "UK",
  "USA",
  "Uruguay",
  "Vanuatu",
  "Vatican City",
  "Vietnam",
  "Virgin Islands (U.S.)",
];

export const allCountryPhoneCode = [
  {
    name: "United States",
    dialCode: "+1",
    code: "US",
  },
  {
    name: "Afghanistan",
    dialCode: "+93",
    code: "AF",
  },
  {
    name: "Aland Islands",
    dialCode: "+358",
    code: "AX",
  },
  {
    name: "Albania",
    dialCode: "+355",
    code: "AL",
  },
  {
    name: "Algeria",
    dialCode: "+213",
    code: "DZ",
  },
  {
    name: "AmericanSamoa",
    dialCode: "+1684",
    code: "AS",
  },
  {
    name: "Andorra",
    dialCode: "+376",
    code: "AD",
  },
  {
    name: "Angola",
    dialCode: "+244",
    code: "AO",
  },
  {
    name: "Anguilla",
    dialCode: "+1264",
    code: "AI",
  },
  {
    name: "Antarctica",
    dialCode: "+672",
    code: "AQ",
  },
  {
    name: "Antigua and Barbuda",
    dialCode: "+1268",
    code: "AG",
  },
  {
    name: "Argentina",
    dialCode: "+54",
    code: "AR",
  },
  {
    name: "Armenia",
    dialCode: "+374",
    code: "AM",
  },
  {
    name: "Aruba",
    dialCode: "+297",
    code: "AW",
  },
  {
    name: "Australia",
    dialCode: "+61",
    code: "AU",
  },
  {
    name: "Austria",
    dialCode: "+43",
    code: "AT",
  },
  {
    name: "Azerbaijan",
    dialCode: "+994",
    code: "AZ",
  },
  {
    name: "Bahamas",
    dialCode: "+1242",
    code: "BS",
  },
  {
    name: "Bahrain",
    dialCode: "+973",
    code: "BH",
  },
  {
    name: "Bangladesh",
    dialCode: "+880",
    code: "BD",
  },
  {
    name: "Barbados",
    dialCode: "+1246",
    code: "BB",
  },
  {
    name: "Belarus",
    dialCode: "+375",
    code: "BY",
  },
  {
    name: "Belgium",
    dialCode: "+32",
    code: "BE",
  },
  {
    name: "Belize",
    dialCode: "+501",
    code: "BZ",
  },
  {
    name: "Benin",
    dialCode: "+229",
    code: "BJ",
  },
  {
    name: "Bermuda",
    dialCode: "+1441",
    code: "BM",
  },
  {
    name: "Bhutan",
    dialCode: "+975",
    code: "BT",
  },
  {
    name: "Bolivia, Plurinational State of",
    dialCode: "+591",
    code: "BO",
  },
  {
    name: "Bosnia and Herzegovina",
    dialCode: "+387",
    code: "BA",
  },
  {
    name: "Botswana",
    dialCode: "+267",
    code: "BW",
  },
  {
    name: "Brazil",
    dialCode: "+55",
    code: "BR",
  },
  {
    name: "British Indian Ocean Territory",
    dialCode: "+246",
    code: "IO",
  },
  {
    name: "Brunei Darussalam",
    dialCode: "+673",
    code: "BN",
  },
  {
    name: "Bulgaria",
    dialCode: "+359",
    code: "BG",
  },
  {
    name: "Burkina Faso",
    dialCode: "+226",
    code: "BF",
  },
  {
    name: "Burundi",
    dialCode: "+257",
    code: "BI",
  },
  {
    name: "Cambodia",
    dialCode: "+855",
    code: "KH",
  },
  {
    name: "Cameroon",
    dialCode: "+237",
    code: "CM",
  },
  {
    name: "Canada",
    dialCode: "+1",
    code: "CA",
  },
  {
    name: "Cape Verde",
    dialCode: "+238",
    code: "CV",
  },
  {
    name: "Cayman Islands",
    dialCode: "+ 345",
    code: "KY",
  },
  {
    name: "Central African Republic",
    dialCode: "+236",
    code: "CF",
  },
  {
    name: "Chad",
    dialCode: "+235",
    code: "TD",
  },
  {
    name: "Chile",
    dialCode: "+56",
    code: "CL",
  },
  {
    name: "China",
    dialCode: "+86",
    code: "CN",
  },
  {
    name: "Christmas Island",
    dialCode: "+61",
    code: "CX",
  },
  {
    name: "Cocos (Keeling) Islands",
    dialCode: "+61",
    code: "CC",
  },
  {
    name: "Colombia",
    dialCode: "+57",
    code: "CO",
  },
  {
    name: "Comoros",
    dialCode: "+269",
    code: "KM",
  },
  {
    name: "Congo",
    dialCode: "+242",
    code: "CG",
  },
  {
    name: "Congo, The Democratic Republic of the Congo",
    dialCode: "+243",
    code: "CD",
  },
  {
    name: "Cook Islands",
    dialCode: "+682",
    code: "CK",
  },
  {
    name: "Costa Rica",
    dialCode: "+506",
    code: "CR",
  },
  {
    name: "Cote d'Ivoire",
    dialCode: "+225",
    code: "CI",
  },
  {
    name: "Croatia",
    dialCode: "+385",
    code: "HR",
  },
  {
    name: "Cuba",
    dialCode: "+53",
    code: "CU",
  },
  {
    name: "Cyprus",
    dialCode: "+357",
    code: "CY",
  },
  {
    name: "Czech Republic",
    dialCode: "+420",
    code: "CZ",
  },
  {
    name: "Denmark",
    dialCode: "+45",
    code: "DK",
  },
  {
    name: "Djibouti",
    dialCode: "+253",
    code: "DJ",
  },
  {
    name: "Dominica",
    dialCode: "+1767",
    code: "DM",
  },
  {
    name: "Dominican Republic",
    dialCode: "+1849",
    code: "DO",
  },
  {
    name: "Ecuador",
    dialCode: "+593",
    code: "EC",
  },
  {
    name: "Egypt",
    dialCode: "+20",
    code: "EG",
  },
  {
    name: "El Salvador",
    dialCode: "+503",
    code: "SV",
  },
  {
    name: "Equatorial Guinea",
    dialCode: "+240",
    code: "GQ",
  },
  {
    name: "Eritrea",
    dialCode: "+291",
    code: "ER",
  },
  {
    name: "Estonia",
    dialCode: "+372",
    code: "EE",
  },
  {
    name: "Ethiopia",
    dialCode: "+251",
    code: "ET",
  },
  {
    name: "Falkland Islands (Malvinas)",
    dialCode: "+500",
    code: "FK",
  },
  {
    name: "Faroe Islands",
    dialCode: "+298",
    code: "FO",
  },
  {
    name: "Fiji",
    dialCode: "+679",
    code: "FJ",
  },
  {
    name: "Finland",
    dialCode: "+358",
    code: "FI",
  },
  {
    name: "France",
    dialCode: "+33",
    code: "FR",
  },
  {
    name: "French Guiana",
    dialCode: "+594",
    code: "GF",
  },
  {
    name: "French Polynesia",
    dialCode: "+689",
    code: "PF",
  },
  {
    name: "Gabon",
    dialCode: "+241",
    code: "GA",
  },
  {
    name: "Gambia",
    dialCode: "+220",
    code: "GM",
  },
  {
    name: "Georgia",
    dialCode: "+995",
    code: "GE",
  },
  {
    name: "Germany",
    dialCode: "+49",
    code: "DE",
  },
  {
    name: "Ghana",
    dialCode: "+233",
    code: "GH",
  },
  {
    name: "Gibraltar",
    dialCode: "+350",
    code: "GI",
  },
  {
    name: "Greece",
    dialCode: "+30",
    code: "GR",
  },
  {
    name: "Greenland",
    dialCode: "+299",
    code: "GL",
  },
  {
    name: "Grenada",
    dialCode: "+1473",
    code: "GD",
  },
  {
    name: "Guadeloupe",
    dialCode: "+590",
    code: "GP",
  },
  {
    name: "Guam",
    dialCode: "+1671",
    code: "GU",
  },
  {
    name: "Guatemala",
    dialCode: "+502",
    code: "GT",
  },
  {
    name: "Guernsey",
    dialCode: "+44",
    code: "GG",
  },
  {
    name: "Guinea",
    dialCode: "+224",
    code: "GN",
  },
  {
    name: "Guinea-Bissau",
    dialCode: "+245",
    code: "GW",
  },
  {
    name: "Guyana",
    dialCode: "+595",
    code: "GY",
  },
  {
    name: "Haiti",
    dialCode: "+509",
    code: "HT",
  },
  {
    name: "Holy See (Vatican City State)",
    dialCode: "+379",
    code: "VA",
  },
  {
    name: "Honduras",
    dialCode: "+504",
    code: "HN",
  },
  {
    name: "Hong Kong",
    dialCode: "+852",
    code: "HK",
  },
  {
    name: "Hungary",
    dialCode: "+36",
    code: "HU",
  },
  {
    name: "Iceland",
    dialCode: "+354",
    code: "IS",
  },
  {
    name: "India",
    dialCode: "+91",
    code: "IN",
  },
  {
    name: "Indonesia",
    dialCode: "+62",
    code: "ID",
  },
  {
    name: "Iran, Islamic Republic of Persian Gulf",
    dialCode: "+98",
    code: "IR",
  },
  {
    name: "Iraq",
    dialCode: "+964",
    code: "IQ",
  },
  {
    name: "Ireland",
    dialCode: "+353",
    code: "IE",
  },
  {
    name: "Isle of Man",
    dialCode: "+44",
    code: "IM",
  },
  {
    name: "Israel",
    dialCode: "+972",
    code: "IL",
  },
  {
    name: "Italy",
    dialCode: "+39",
    code: "IT",
  },
  {
    name: "Jamaica",
    dialCode: "+1876",
    code: "JM",
  },
  {
    name: "Japan",
    dialCode: "+81",
    code: "JP",
  },
  {
    name: "Jersey",
    dialCode: "+44",
    code: "JE",
  },
  {
    name: "Jordan",
    dialCode: "+962",
    code: "JO",
  },
  {
    name: "Kazakhstan",
    dialCode: "+77",
    code: "KZ",
  },
  {
    name: "Kenya",
    dialCode: "+254",
    code: "KE",
  },
  {
    name: "Kiribati",
    dialCode: "+686",
    code: "KI",
  },
  {
    name: "Korea, Democratic People's Republic of Korea",
    dialCode: "+850",
    code: "KP",
  },
  {
    name: "Korea, Republic of South Korea",
    dialCode: "+82",
    code: "KR",
  },
  {
    name: "Kuwait",
    dialCode: "+965",
    code: "KW",
  },
  {
    name: "Kyrgyzstan",
    dialCode: "+996",
    code: "KG",
  },
  {
    name: "Laos",
    dialCode: "+856",
    code: "LA",
  },
  {
    name: "Latvia",
    dialCode: "+371",
    code: "LV",
  },
  {
    name: "Lebanon",
    dialCode: "+961",
    code: "LB",
  },
  {
    name: "Lesotho",
    dialCode: "+266",
    code: "LS",
  },
  {
    name: "Liberia",
    dialCode: "+231",
    code: "LR",
  },
  {
    name: "Libyan Arab Jamahiriya",
    dialCode: "+218",
    code: "LY",
  },
  {
    name: "Liechtenstein",
    dialCode: "+423",
    code: "LI",
  },
  {
    name: "Lithuania",
    dialCode: "+370",
    code: "LT",
  },
  {
    name: "Luxembourg",
    dialCode: "+352",
    code: "LU",
  },
  {
    name: "Macao",
    dialCode: "+853",
    code: "MO",
  },
  {
    name: "Macedonia",
    dialCode: "+389",
    code: "MK",
  },
  {
    name: "Madagascar",
    dialCode: "+261",
    code: "MG",
  },
  {
    name: "Malawi",
    dialCode: "+265",
    code: "MW",
  },
  {
    name: "Malaysia",
    dialCode: "+60",
    code: "MY",
  },
  {
    name: "Maldives",
    dialCode: "+960",
    code: "MV",
  },
  {
    name: "Mali",
    dialCode: "+223",
    code: "ML",
  },
  {
    name: "Malta",
    dialCode: "+356",
    code: "MT",
  },
  {
    name: "Marshall Islands",
    dialCode: "+692",
    code: "MH",
  },
  {
    name: "Martinique",
    dialCode: "+596",
    code: "MQ",
  },
  {
    name: "Mauritania",
    dialCode: "+222",
    code: "MR",
  },
  {
    name: "Mauritius",
    dialCode: "+230",
    code: "MU",
  },
  {
    name: "Mayotte",
    dialCode: "+262",
    code: "YT",
  },
  {
    name: "Mexico",
    dialCode: "+52",
    code: "MX",
  },
  {
    name: "Micronesia, Federated States of Micronesia",
    dialCode: "+691",
    code: "FM",
  },
  {
    name: "Moldova",
    dialCode: "+373",
    code: "MD",
  },
  {
    name: "Monaco",
    dialCode: "+377",
    code: "MC",
  },
  {
    name: "Mongolia",
    dialCode: "+976",
    code: "MN",
  },
  {
    name: "Montenegro",
    dialCode: "+382",
    code: "ME",
  },
  {
    name: "Montserrat",
    dialCode: "+1664",
    code: "MS",
  },
  {
    name: "Morocco",
    dialCode: "+212",
    code: "MA",
  },
  {
    name: "Mozambique",
    dialCode: "+258",
    code: "MZ",
  },
  {
    name: "Myanmar",
    dialCode: "+95",
    code: "MM",
  },
  {
    name: "Namibia",
    dialCode: "+264",
    code: "NA",
  },
  {
    name: "Nauru",
    dialCode: "+674",
    code: "NR",
  },
  {
    name: "Nepal",
    dialCode: "+977",
    code: "NP",
  },
  {
    name: "Netherlands",
    dialCode: "+31",
    code: "NL",
  },
  {
    name: "Netherlands Antilles",
    dialCode: "+599",
    code: "AN",
  },
  {
    name: "New Caledonia",
    dialCode: "+687",
    code: "NC",
  },
  {
    name: "New Zealand",
    dialCode: "+64",
    code: "NZ",
  },
  {
    name: "Nicaragua",
    dialCode: "+505",
    code: "NI",
  },
  {
    name: "Niger",
    dialCode: "+227",
    code: "NE",
  },
  {
    name: "Nigeria",
    dialCode: "+234",
    code: "NG",
  },
  {
    name: "Niue",
    dialCode: "+683",
    code: "NU",
  },
  {
    name: "Norfolk Island",
    dialCode: "+672",
    code: "NF",
  },
  {
    name: "Northern Mariana Islands",
    dialCode: "+1670",
    code: "MP",
  },
  {
    name: "Norway",
    dialCode: "+47",
    code: "NO",
  },
  {
    name: "Oman",
    dialCode: "+968",
    code: "OM",
  },
  {
    name: "Pakistan",
    dialCode: "+92",
    code: "PK",
  },
  {
    name: "Palau",
    dialCode: "+680",
    code: "PW",
  },
  {
    name: "Palestinian Territory, Occupied",
    dialCode: "+970",
    code: "PS",
  },
  {
    name: "Panama",
    dialCode: "+507",
    code: "PA",
  },
  {
    name: "Papua New Guinea",
    dialCode: "+675",
    code: "PG",
  },
  {
    name: "Paraguay",
    dialCode: "+595",
    code: "PY",
  },
  {
    name: "Peru",
    dialCode: "+51",
    code: "PE",
  },
  {
    name: "Philippines",
    dialCode: "+63",
    code: "PH",
  },
  {
    name: "Pitcairn",
    dialCode: "+872",
    code: "PN",
  },
  {
    name: "Poland",
    dialCode: "+48",
    code: "PL",
  },
  {
    name: "Portugal",
    dialCode: "+351",
    code: "PT",
  },
  {
    name: "Puerto Rico",
    dialCode: "+1939",
    code: "PR",
  },
  {
    name: "Qatar",
    dialCode: "+974",
    code: "QA",
  },
  {
    name: "Romania",
    dialCode: "+40",
    code: "RO",
  },
  {
    name: "Russia",
    dialCode: "+7",
    code: "RU",
  },
  {
    name: "Rwanda",
    dialCode: "+250",
    code: "RW",
  },
  {
    name: "Reunion",
    dialCode: "+262",
    code: "RE",
  },
  {
    name: "Saint Barthelemy",
    dialCode: "+590",
    code: "BL",
  },
  {
    name: "Saint Helena, Ascension and Tristan Da Cunha",
    dialCode: "+290",
    code: "SH",
  },
  {
    name: "Saint Kitts and Nevis",
    dialCode: "+1869",
    code: "KN",
  },
  {
    name: "Saint Lucia",
    dialCode: "+1758",
    code: "LC",
  },
  {
    name: "Saint Martin",
    dialCode: "+590",
    code: "MF",
  },
  {
    name: "Saint Pierre and Miquelon",
    dialCode: "+508",
    code: "PM",
  },
  {
    name: "Saint Vincent and the Grenadines",
    dialCode: "+1784",
    code: "VC",
  },
  {
    name: "Samoa",
    dialCode: "+685",
    code: "WS",
  },
  {
    name: "San Marino",
    dialCode: "+378",
    code: "SM",
  },
  {
    name: "Sao Tome and Principe",
    dialCode: "+239",
    code: "ST",
  },
  {
    name: "Saudi Arabia",
    dialCode: "+966",
    code: "SA",
  },
  {
    name: "Senegal",
    dialCode: "+221",
    code: "SN",
  },
  {
    name: "Serbia",
    dialCode: "+381",
    code: "RS",
  },
  {
    name: "Seychelles",
    dialCode: "+248",
    code: "SC",
  },
  {
    name: "Sierra Leone",
    dialCode: "+232",
    code: "SL",
  },
  {
    name: "Singapore",
    dialCode: "+65",
    code: "SG",
  },
  {
    name: "Slovakia",
    dialCode: "+421",
    code: "SK",
  },
  {
    name: "Slovenia",
    dialCode: "+386",
    code: "SI",
  },
  {
    name: "Solomon Islands",
    dialCode: "+677",
    code: "SB",
  },
  {
    name: "Somalia",
    dialCode: "+252",
    code: "SO",
  },
  {
    name: "South Africa",
    dialCode: "+27",
    code: "ZA",
  },
  {
    name: "South Sudan",
    dialCode: "+211",
    code: "SS",
  },
  {
    name: "South Georgia and the South Sandwich Islands",
    dialCode: "+500",
    code: "GS",
  },
  {
    name: "Spain",
    dialCode: "+34",
    code: "ES",
  },
  {
    name: "Sri Lanka",
    dialCode: "+94",
    code: "LK",
  },
  {
    name: "Sudan",
    dialCode: "+249",
    code: "SD",
  },
  {
    name: "Suriname",
    dialCode: "+597",
    code: "SR",
  },
  {
    name: "Svalbard and Jan Mayen",
    dialCode: "+47",
    code: "SJ",
  },
  {
    name: "Swaziland",
    dialCode: "+268",
    code: "SZ",
  },
  {
    name: "Sweden",
    dialCode: "+46",
    code: "SE",
  },
  {
    name: "Switzerland",
    dialCode: "+41",
    code: "CH",
  },
  {
    name: "Syrian Arab Republic",
    dialCode: "+963",
    code: "SY",
  },
  {
    name: "Taiwan",
    dialCode: "+886",
    code: "TW",
  },
  {
    name: "Tajikistan",
    dialCode: "+992",
    code: "TJ",
  },
  {
    name: "Tanzania, United Republic of Tanzania",
    dialCode: "+255",
    code: "TZ",
  },
  {
    name: "Thailand",
    dialCode: "+66",
    code: "TH",
  },
  {
    name: "Timor-Leste",
    dialCode: "+670",
    code: "TL",
  },
  {
    name: "Togo",
    dialCode: "+228",
    code: "TG",
  },
  {
    name: "Tokelau",
    dialCode: "+690",
    code: "TK",
  },
  {
    name: "Tonga",
    dialCode: "+676",
    code: "TO",
  },
  {
    name: "Trinidad and Tobago",
    dialCode: "+1868",
    code: "TT",
  },
  {
    name: "Tunisia",
    dialCode: "+216",
    code: "TN",
  },
  {
    name: "Turkey",
    dialCode: "+90",
    code: "TR",
  },
  {
    name: "Turkmenistan",
    dialCode: "+993",
    code: "TM",
  },
  {
    name: "Turks and Caicos Islands",
    dialCode: "+1649",
    code: "TC",
  },
  {
    name: "Tuvalu",
    dialCode: "+688",
    code: "TV",
  },
  {
    name: "Uganda",
    dialCode: "+256",
    code: "UG",
  },
  {
    name: "Ukraine",
    dialCode: "+380",
    code: "UA",
  },
  {
    name: "United Arab Emirates",
    dialCode: "+971",
    code: "AE",
  },
  {
    name: "United Kingdom",
    dialCode: "+44",
    code: "GB",
  },
  {
    name: "Uruguay",
    dialCode: "+598",
    code: "UY",
  },
  {
    name: "Uzbekistan",
    dialCode: "+998",
    code: "UZ",
  },
  {
    name: "Vanuatu",
    dialCode: "+678",
    code: "VU",
  },
  {
    name: "Venezuela, Bolivarian Republic of Venezuela",
    dialCode: "+58",
    code: "VE",
  },
  {
    name: "Vietnam",
    dialCode: "+84",
    code: "VN",
  },
  {
    name: "Virgin Islands, British",
    dialCode: "+1284",
    code: "VG",
  },
  {
    name: "Virgin Islands, U.S.",
    dialCode: "+1340",
    code: "VI",
  },
  {
    name: "Wallis and Futuna",
    dialCode: "+681",
    code: "WF",
  },
  {
    name: "Yemen",
    dialCode: "+967",
    code: "YE",
  },
  {
    name: "Zambia",
    dialCode: "+260",
    code: "ZM",
  },
  {
    name: "Zimbabwe",
    dialCode: "+263",
    code: "ZW",
  },
];

export const allCountriesWithFlags = [
  {
    code: "AC",
    unicode: "U+1F1E6 U+1F1E8",
    name: "Ascension Island",
    emoji: "🇦🇨",
  },
  {
    code: "AD",
    unicode: "U+1F1E6 U+1F1E9",
    name: "Andorra",
    emoji: "🇦🇩",
  },
  {
    code: "AE",
    unicode: "U+1F1E6 U+1F1EA",
    name: "UAE",
    emoji: "🇦🇪",
  },
  {
    code: "AF",
    unicode: "U+1F1E6 U+1F1EB",
    name: "Afghanistan",
    emoji: "🇦🇫",
  },
  {
    code: "AG",
    unicode: "U+1F1E6 U+1F1EC",
    name: "Antigua and Barbuda",
    emoji: "🇦🇬",
  },
  {
    code: "AI",
    unicode: "U+1F1E6 U+1F1EE",
    name: "Anguilla",
    emoji: "🇦🇮",
  },
  {
    code: "AL",
    unicode: "U+1F1E6 U+1F1F1",
    name: "Albania",
    emoji: "🇦🇱",
  },
  {
    code: "AM",
    unicode: "U+1F1E6 U+1F1F2",
    name: "Armenia",
    emoji: "🇦🇲",
  },
  {
    code: "AO",
    unicode: "U+1F1E6 U+1F1F4",
    name: "Angola",
    emoji: "🇦🇴",
  },
  {
    code: "AQ",
    unicode: "U+1F1E6 U+1F1F6",
    name: "Antarctica",
    emoji: "🇦🇶",
  },
  {
    code: "AR",
    unicode: "U+1F1E6 U+1F1F7",
    name: "Argentina",
    emoji: "🇦🇷",
  },
  {
    code: "AS",
    unicode: "U+1F1E6 U+1F1F8",
    name: "American Samoa",
    emoji: "🇦🇸",
  },
  {
    code: "AT",
    unicode: "U+1F1E6 U+1F1F9",
    name: "Austria",
    emoji: "🇦🇹",
  },
  {
    code: "AU",
    unicode: "U+1F1E6 U+1F1FA",
    name: "Australia",
    emoji: "🇦🇺",
  },
  {
    code: "AW",
    unicode: "U+1F1E6 U+1F1FC",
    name: "Aruba",
    emoji: "🇦🇼",
  },
  {
    code: "AX",
    unicode: "U+1F1E6 U+1F1FD",
    name: "Åland Islands",
    emoji: "🇦🇽",
  },
  {
    code: "AZ",
    unicode: "U+1F1E6 U+1F1FF",
    name: "Azerbaijan",
    emoji: "🇦🇿",
  },
  {
    code: "BA",
    unicode: "U+1F1E7 U+1F1E6",
    name: "Bosnia and Herzegovina",
    emoji: "🇧🇦",
  },
  {
    code: "BB",
    unicode: "U+1F1E7 U+1F1E7",
    name: "Barbados",
    emoji: "🇧🇧",
  },
  {
    code: "BD",
    unicode: "U+1F1E7 U+1F1E9",
    name: "Bangladesh",
    emoji: "🇧🇩",
  },
  {
    code: "BE",
    unicode: "U+1F1E7 U+1F1EA",
    name: "Belgium",
    emoji: "🇧🇪",
  },
  {
    code: "BF",
    unicode: "U+1F1E7 U+1F1EB",
    name: "Burkina Faso",
    emoji: "🇧🇫",
  },
  {
    code: "BG",
    unicode: "U+1F1E7 U+1F1EC",
    name: "Bulgaria",
    emoji: "🇧🇬",
  },
  {
    code: "BH",
    unicode: "U+1F1E7 U+1F1ED",
    name: "Bahrain",
    emoji: "🇧🇭",
  },
  {
    code: "BI",
    unicode: "U+1F1E7 U+1F1EE",
    name: "Burundi",
    emoji: "🇧🇮",
  },
  {
    code: "BJ",
    unicode: "U+1F1E7 U+1F1EF",
    name: "Benin",
    emoji: "🇧🇯",
  },
  {
    code: "BL",
    unicode: "U+1F1E7 U+1F1F1",
    name: "St. Barthélemy",
    emoji: "🇧🇱",
  },
  {
    code: "BM",
    unicode: "U+1F1E7 U+1F1F2",
    name: "Bermuda",
    emoji: "🇧🇲",
  },
  {
    code: "BN",
    unicode: "U+1F1E7 U+1F1F3",
    name: "Brunei Darussalam",
    emoji: "🇧🇳",
  },
  {
    code: "BO",
    unicode: "U+1F1E7 U+1F1F4",
    name: "Bolivia",
    emoji: "🇧🇴",
  },
  {
    code: "BQ",
    unicode: "U+1F1E7 U+1F1F6",
    name: "Caribbean Netherlands",
    emoji: "🇧🇶",
  },
  {
    code: "BR",
    unicode: "U+1F1E7 U+1F1F7",
    name: "Brazil",
    emoji: "🇧🇷",
  },
  {
    code: "BS",
    unicode: "U+1F1E7 U+1F1F8",
    name: "Bahamas",
    emoji: "🇧🇸",
  },
  {
    code: "BT",
    unicode: "U+1F1E7 U+1F1F9",
    name: "Bhutan",
    emoji: "🇧🇹",
  },
  {
    code: "BV",
    unicode: "U+1F1E7 U+1F1FB",
    name: "Bouvet Island",
    emoji: "🇧🇻",
  },
  {
    code: "BW",
    unicode: "U+1F1E7 U+1F1FC",
    name: "Botswana",
    emoji: "🇧🇼",
  },
  {
    code: "BY",
    unicode: "U+1F1E7 U+1F1FE",
    name: "Belarus",
    emoji: "🇧🇾",
  },
  {
    code: "BZ",
    unicode: "U+1F1E7 U+1F1FF",
    name: "Belize",
    emoji: "🇧🇿",
  },
  {
    code: "CA",
    unicode: "U+1F1E8 U+1F1E6",
    name: "Canada",
    emoji: "🇨🇦",
  },
  {
    code: "CC",
    unicode: "U+1F1E8 U+1F1E8",
    name: "Cocos (Keeling) Islands",
    emoji: "🇨🇨",
  },
  {
    code: "CD",
    unicode: "U+1F1E8 U+1F1E9",
    name: "Congo - Kinshasa",
    emoji: "🇨🇩",
  },
  {
    code: "CF",
    unicode: "U+1F1E8 U+1F1EB",
    name: "Central African Republic",
    emoji: "🇨🇫",
  },
  {
    code: "CG",
    unicode: "U+1F1E8 U+1F1EC",
    name: "Congo - Brazzaville",
    emoji: "🇨🇬",
  },
  {
    code: "CH",
    unicode: "U+1F1E8 U+1F1ED",
    name: "Switzerland",
    emoji: "🇨🇭",
  },
  {
    code: "CI",
    unicode: "U+1F1E8 U+1F1EE",
    name: "Côte d’Ivoire",
    emoji: "🇨🇮",
  },
  {
    code: "CK",
    unicode: "U+1F1E8 U+1F1F0",
    name: "Cook Islands",
    emoji: "🇨🇰",
  },
  {
    code: "CL",
    unicode: "U+1F1E8 U+1F1F1",
    name: "Chile",
    emoji: "🇨🇱",
  },
  {
    code: "CM",
    unicode: "U+1F1E8 U+1F1F2",
    name: "Cameroon",
    emoji: "🇨🇲",
  },
  {
    code: "CN",
    unicode: "U+1F1E8 U+1F1F3",
    name: "China",
    emoji: "🇨🇳",
  },
  {
    code: "CO",
    unicode: "U+1F1E8 U+1F1F4",
    name: "Colombia",
    emoji: "🇨🇴",
  },
  {
    code: "CP",
    unicode: "U+1F1E8 U+1F1F5",
    name: "Clipperton Island",
    emoji: "🇨🇵",
  },
  {
    code: "CR",
    unicode: "U+1F1E8 U+1F1F7",
    name: "Costa Rica",
    emoji: "🇨🇷",
  },
  {
    code: "CU",
    unicode: "U+1F1E8 U+1F1FA",
    name: "Cuba",
    emoji: "🇨🇺",
  },
  {
    code: "CV",
    unicode: "U+1F1E8 U+1F1FB",
    name: "Cape Verde",
    emoji: "🇨🇻",
  },
  {
    code: "CW",
    unicode: "U+1F1E8 U+1F1FC",
    name: "Curaçao",
    emoji: "🇨🇼",
  },
  {
    code: "CX",
    unicode: "U+1F1E8 U+1F1FD",
    name: "Christmas Island",
    emoji: "🇨🇽",
  },
  {
    code: "CY",
    unicode: "U+1F1E8 U+1F1FE",
    name: "Cyprus",
    emoji: "🇨🇾",
  },
  {
    code: "CZ",
    unicode: "U+1F1E8 U+1F1FF",
    name: "Czech Republic",
    emoji: "🇨🇿",
  },
  {
    code: "DE",
    unicode: "U+1F1E9 U+1F1EA",
    name: "Germany",
    emoji: "🇩🇪",
  },
  {
    code: "DG",
    unicode: "U+1F1E9 U+1F1EC",
    name: "Diego Garcia",
    emoji: "🇩🇬",
  },
  {
    code: "DJ",
    unicode: "U+1F1E9 U+1F1EF",
    name: "Djibouti",
    emoji: "🇩🇯",
  },
  {
    code: "DK",
    unicode: "U+1F1E9 U+1F1F0",
    name: "Denmark",
    emoji: "🇩🇰",
  },
  {
    code: "DM",
    unicode: "U+1F1E9 U+1F1F2",
    name: "Dominica",
    emoji: "🇩🇲",
  },
  {
    code: "DO",
    unicode: "U+1F1E9 U+1F1F4",
    name: "Dominican Republic",
    emoji: "🇩🇴",
  },
  {
    code: "DZ",
    unicode: "U+1F1E9 U+1F1FF",
    name: "Algeria",
    emoji: "🇩🇿",
  },
  {
    code: "EA",
    unicode: "U+1F1EA U+1F1E6",
    name: "Ceuta and Melilla",
    emoji: "🇪🇦",
  },
  {
    code: "EC",
    unicode: "U+1F1EA U+1F1E8",
    name: "Ecuador",
    emoji: "🇪🇨",
  },
  {
    code: "EE",
    unicode: "U+1F1EA U+1F1EA",
    name: "Estonia",
    emoji: "🇪🇪",
  },
  {
    code: "EG",
    unicode: "U+1F1EA U+1F1EC",
    name: "Egypt",
    emoji: "🇪🇬",
  },
  {
    code: "EH",
    unicode: "U+1F1EA U+1F1ED",
    name: "Western Sahara",
    emoji: "🇪🇭",
  },
  {
    code: "ER",
    unicode: "U+1F1EA U+1F1F7",
    name: "Eritrea",
    emoji: "🇪🇷",
  },
  {
    code: "ES",
    unicode: "U+1F1EA U+1F1F8",
    name: "Spain",
    emoji: "🇪🇸",
  },
  {
    code: "ET",
    unicode: "U+1F1EA U+1F1F9",
    name: "Ethiopia",
    emoji: "🇪🇹",
  },
  {
    code: "EU",
    unicode: "U+1F1EA U+1F1FA",
    name: "European Union",
    emoji: "🇪🇺",
  },
  {
    code: "FI",
    unicode: "U+1F1EB U+1F1EE",
    name: "Finland",
    emoji: "🇫🇮",
  },
  {
    code: "FJ",
    unicode: "U+1F1EB U+1F1EF",
    name: "Fiji",
    emoji: "🇫🇯",
  },
  {
    code: "FK",
    unicode: "U+1F1EB U+1F1F0",
    name: "Falkland Islands",
    emoji: "🇫🇰",
  },
  {
    code: "FM",
    unicode: "U+1F1EB U+1F1F2",
    name: "Federated States of Micronesia",
    emoji: "🇫🇲",
  },
  {
    code: "FO",
    unicode: "U+1F1EB U+1F1F4",
    name: "Faroe Islands",
    emoji: "🇫🇴",
  },
  {
    code: "FR",
    unicode: "U+1F1EB U+1F1F7",
    name: "France",
    emoji: "🇫🇷",
  },
  {
    code: "GA",
    unicode: "U+1F1EC U+1F1E6",
    name: "Gabon",
    emoji: "🇬🇦",
  },
  {
    code: "GB",
    unicode: "U+1F1EC U+1F1E7",
    name: "UK",
    emoji: "🇬🇧",
  },
  {
    code: "GD",
    unicode: "U+1F1EC U+1F1E9",
    name: "Grenada",
    emoji: "🇬🇩",
  },
  {
    code: "GE",
    unicode: "U+1F1EC U+1F1EA",
    name: "Georgia",
    emoji: "🇬🇪",
  },
  {
    code: "GF",
    unicode: "U+1F1EC U+1F1EB",
    name: "French Guiana",
    emoji: "🇬🇫",
  },
  {
    code: "GG",
    unicode: "U+1F1EC U+1F1EC",
    name: "Guernsey",
    emoji: "🇬🇬",
  },
  {
    code: "GH",
    unicode: "U+1F1EC U+1F1ED",
    name: "Ghana",
    emoji: "🇬🇭",
  },
  {
    code: "GI",
    unicode: "U+1F1EC U+1F1EE",
    name: "Gibraltar",
    emoji: "🇬🇮",
  },
  {
    code: "GL",
    unicode: "U+1F1EC U+1F1F1",
    name: "Greenland",
    emoji: "🇬🇱",
  },
  {
    code: "GM",
    unicode: "U+1F1EC U+1F1F2",
    name: "Gambia",
    emoji: "🇬🇲",
  },
  {
    code: "GN",
    unicode: "U+1F1EC U+1F1F3",
    name: "Guinea",
    emoji: "🇬🇳",
  },
  {
    code: "GP",
    unicode: "U+1F1EC U+1F1F5",
    name: "Guadeloupe",
    emoji: "🇬🇵",
  },
  {
    code: "GQ",
    unicode: "U+1F1EC U+1F1F6",
    name: "Equatorial Guinea",
    emoji: "🇬🇶",
  },
  {
    code: "GR",
    unicode: "U+1F1EC U+1F1F7",
    name: "Greece",
    emoji: "🇬🇷",
  },
  {
    code: "GS",
    unicode: "U+1F1EC U+1F1F8",
    name: "South Georgia and South Sandwich Islands",
    emoji: "🇬🇸",
  },
  {
    code: "GT",
    unicode: "U+1F1EC U+1F1F9",
    name: "Guatemala",
    emoji: "🇬🇹",
  },
  {
    code: "GU",
    unicode: "U+1F1EC U+1F1FA",
    name: "Guam",
    emoji: "🇬🇺",
  },
  {
    code: "GW",
    unicode: "U+1F1EC U+1F1FC",
    name: "Guinea-Bissau",
    emoji: "🇬🇼",
  },
  {
    code: "GY",
    unicode: "U+1F1EC U+1F1FE",
    name: "Guyana",
    emoji: "🇬🇾",
  },
  {
    code: "HK",
    unicode: "U+1F1ED U+1F1F0",
    name: "Hong Kong",
    emoji: "🇭🇰",
  },
  {
    code: "HM",
    unicode: "U+1F1ED U+1F1F2",
    name: "Heard and McDonald Islands",
    emoji: "🇭🇲",
  },
  {
    code: "HN",
    unicode: "U+1F1ED U+1F1F3",
    name: "Honduras",
    emoji: "🇭🇳",
  },
  {
    code: "HR",
    unicode: "U+1F1ED U+1F1F7",
    name: "Croatia",
    emoji: "🇭🇷",
  },
  {
    code: "HT",
    unicode: "U+1F1ED U+1F1F9",
    name: "Haiti",
    emoji: "🇭🇹",
  },
  {
    code: "HU",
    unicode: "U+1F1ED U+1F1FA",
    name: "Hungary",
    emoji: "🇭🇺",
  },
  {
    code: "IC",
    unicode: "U+1F1EE U+1F1E8",
    name: "Canary Islands",
    emoji: "🇮🇨",
  },
  {
    code: "ID",
    unicode: "U+1F1EE U+1F1E9",
    name: "Indonesia",
    emoji: "🇮🇩",
  },
  {
    code: "IE",
    unicode: "U+1F1EE U+1F1EA",
    name: "Ireland",
    emoji: "🇮🇪",
  },
  {
    code: "IL",
    unicode: "U+1F1EE U+1F1F1",
    name: "Israel",
    emoji: "🇮🇱",
  },
  {
    code: "IM",
    unicode: "U+1F1EE U+1F1F2",
    name: "Isle of Man",
    emoji: "🇮🇲",
  },
  {
    code: "IN",
    unicode: "U+1F1EE U+1F1F3",
    name: "India",
    emoji: "🇮🇳",
  },
  {
    code: "IO",
    unicode: "U+1F1EE U+1F1F4",
    name: "British Indian Ocean Territory",
    emoji: "🇮🇴",
  },
  {
    code: "IQ",
    unicode: "U+1F1EE U+1F1F6",
    name: "Iraq",
    emoji: "🇮🇶",
  },
  {
    code: "IR",
    unicode: "U+1F1EE U+1F1F7",
    name: "Iran",
    emoji: "🇮🇷",
  },
  {
    code: "IS",
    unicode: "U+1F1EE U+1F1F8",
    name: "Iceland",
    emoji: "🇮🇸",
  },
  {
    code: "IT",
    unicode: "U+1F1EE U+1F1F9",
    name: "Italy",
    emoji: "🇮🇹",
  },
  {
    code: "JE",
    unicode: "U+1F1EF U+1F1EA",
    name: "Jersey",
    emoji: "🇯🇪",
  },
  {
    code: "JM",
    unicode: "U+1F1EF U+1F1F2",
    name: "Jamaica",
    emoji: "🇯🇲",
  },
  {
    code: "JO",
    unicode: "U+1F1EF U+1F1F4",
    name: "Jordan",
    emoji: "🇯🇴",
  },
  {
    code: "JP",
    unicode: "U+1F1EF U+1F1F5",
    name: "Japan",
    emoji: "🇯🇵",
  },
  {
    code: "KE",
    unicode: "U+1F1F0 U+1F1EA",
    name: "Kenya",
    emoji: "🇰🇪",
  },
  {
    code: "KG",
    unicode: "U+1F1F0 U+1F1EC",
    name: "Kyrgyzstan",
    emoji: "🇰🇬",
  },
  {
    code: "KH",
    unicode: "U+1F1F0 U+1F1ED",
    name: "Cambodia",
    emoji: "🇰🇭",
  },
  {
    code: "KI",
    unicode: "U+1F1F0 U+1F1EE",
    name: "Kiribati",
    emoji: "🇰🇮",
  },
  {
    code: "KM",
    unicode: "U+1F1F0 U+1F1F2",
    name: "Comoros",
    emoji: "🇰🇲",
  },
  {
    code: "KN",
    unicode: "U+1F1F0 U+1F1F3",
    name: "St. Kitts and Nevis",
    emoji: "🇰🇳",
  },
  {
    code: "KP",
    unicode: "U+1F1F0 U+1F1F5",
    name: "North Korea",
    emoji: "🇰🇵",
  },
  {
    code: "KR",
    unicode: "U+1F1F0 U+1F1F7",
    name: "South Korea",
    emoji: "🇰🇷",
  },
  {
    code: "KW",
    unicode: "U+1F1F0 U+1F1FC",
    name: "Kuwait",
    emoji: "🇰🇼",
  },
  {
    code: "KY",
    unicode: "U+1F1F0 U+1F1FE",
    name: "Cayman Islands",
    emoji: "🇰🇾",
  },
  {
    code: "KZ",
    unicode: "U+1F1F0 U+1F1FF",
    name: "Kazakhstan",
    emoji: "🇰🇿",
  },
  {
    code: "LA",
    unicode: "U+1F1F1 U+1F1E6",
    name: "Laos",
    emoji: "🇱🇦",
  },
  {
    code: "LB",
    unicode: "U+1F1F1 U+1F1E7",
    name: "Lebanon",
    emoji: "🇱🇧",
  },
  {
    code: "LC",
    unicode: "U+1F1F1 U+1F1E8",
    name: "St. Lucia",
    emoji: "🇱🇨",
  },
  {
    code: "LI",
    unicode: "U+1F1F1 U+1F1EE",
    name: "Liechtenstein",
    emoji: "🇱🇮",
  },
  {
    code: "LK",
    unicode: "U+1F1F1 U+1F1F0",
    name: "Sri lanka",
    emoji: "🇱🇰",
  },
  {
    code: "LR",
    unicode: "U+1F1F1 U+1F1F7",
    name: "Liberia",
    emoji: "🇱🇷",
  },
  {
    code: "LS",
    unicode: "U+1F1F1 U+1F1F8",
    name: "Lesotho",
    emoji: "🇱🇸",
  },
  {
    code: "LT",
    unicode: "U+1F1F1 U+1F1F9",
    name: "Lithuania",
    emoji: "🇱🇹",
  },
  {
    code: "LU",
    unicode: "U+1F1F1 U+1F1FA",
    name: "Luxembourg",
    emoji: "🇱🇺",
  },
  {
    code: "LV",
    unicode: "U+1F1F1 U+1F1FB",
    name: "Latvia",
    emoji: "🇱🇻",
  },
  {
    code: "LY",
    unicode: "U+1F1F1 U+1F1FE",
    name: "Libya",
    emoji: "🇱🇾",
  },
  {
    code: "MA",
    unicode: "U+1F1F2 U+1F1E6",
    name: "Morocco",
    emoji: "🇲🇦",
  },
  {
    code: "MC",
    unicode: "U+1F1F2 U+1F1E8",
    name: "Monaco",
    emoji: "🇲🇨",
  },
  {
    code: "MD",
    unicode: "U+1F1F2 U+1F1E9",
    name: "Moldova",
    emoji: "🇲🇩",
  },
  {
    code: "ME",
    unicode: "U+1F1F2 U+1F1EA",
    name: "Montenegro",
    emoji: "🇲🇪",
  },
  {
    code: "MF",
    unicode: "U+1F1F2 U+1F1EB",
    name: "St. Martin",
    emoji: "🇲🇫",
  },
  {
    code: "MG",
    unicode: "U+1F1F2 U+1F1EC",
    name: "Madagascar",
    emoji: "🇲🇬",
  },
  {
    code: "MH",
    unicode: "U+1F1F2 U+1F1ED",
    name: "Marshall Islands",
    emoji: "🇲🇭",
  },
  {
    code: "MK",
    unicode: "U+1F1F2 U+1F1F0",
    name: "Macedonia",
    emoji: "🇲🇰",
  },
  {
    code: "ML",
    unicode: "U+1F1F2 U+1F1F1",
    name: "Mali",
    emoji: "🇲🇱",
  },
  {
    code: "MM",
    unicode: "U+1F1F2 U+1F1F2",
    name: "Myanmar (Burma)",
    emoji: "🇲🇲",
  },
  {
    code: "MN",
    unicode: "U+1F1F2 U+1F1F3",
    name: "Mongolia",
    emoji: "🇲🇳",
  },
  {
    code: "MO",
    unicode: "U+1F1F2 U+1F1F4",
    name: "Macau SAR China",
    emoji: "🇲🇴",
  },
  {
    code: "MP",
    unicode: "U+1F1F2 U+1F1F5",
    name: "Northern Mariana Islands",
    emoji: "🇲🇵",
  },
  {
    code: "MQ",
    unicode: "U+1F1F2 U+1F1F6",
    name: "Martinique",
    emoji: "🇲🇶",
  },
  {
    code: "MR",
    unicode: "U+1F1F2 U+1F1F7",
    name: "Mauritania",
    emoji: "🇲🇷",
  },
  {
    code: "MS",
    unicode: "U+1F1F2 U+1F1F8",
    name: "Montserrat",
    emoji: "🇲🇸",
  },
  {
    code: "MT",
    unicode: "U+1F1F2 U+1F1F9",
    name: "Malta",
    emoji: "🇲🇹",
  },
  {
    code: "MU",
    unicode: "U+1F1F2 U+1F1FA",
    name: "Mauritius",
    emoji: "🇲🇺",
  },
  {
    code: "MV",
    unicode: "U+1F1F2 U+1F1FB",
    name: "Maldives",
    emoji: "🇲🇻",
  },
  {
    code: "MW",
    unicode: "U+1F1F2 U+1F1FC",
    name: "Malawi",
    emoji: "🇲🇼",
  },
  {
    code: "MX",
    unicode: "U+1F1F2 U+1F1FD",
    name: "Mexico",
    emoji: "🇲🇽",
  },
  {
    code: "MY",
    unicode: "U+1F1F2 U+1F1FE",
    name: "Malaysia",
    emoji: "🇲🇾",
  },
  {
    code: "MZ",
    unicode: "U+1F1F2 U+1F1FF",
    name: "Mozambique",
    emoji: "🇲🇿",
  },
  {
    code: "NA",
    unicode: "U+1F1F3 U+1F1E6",
    name: "Namibia",
    emoji: "🇳🇦",
  },
  {
    code: "NC",
    unicode: "U+1F1F3 U+1F1E8",
    name: "New Caledonia",
    emoji: "🇳🇨",
  },
  {
    code: "NE",
    unicode: "U+1F1F3 U+1F1EA",
    name: "Niger",
    emoji: "🇳🇪",
  },
  {
    code: "NF",
    unicode: "U+1F1F3 U+1F1EB",
    name: "Norfolk Island",
    emoji: "🇳🇫",
  },
  {
    code: "NG",
    unicode: "U+1F1F3 U+1F1EC",
    name: "Nigeria",
    emoji: "🇳🇬",
  },
  {
    code: "NI",
    unicode: "U+1F1F3 U+1F1EE",
    name: "Nicaragua",
    emoji: "🇳🇮",
  },
  {
    code: "NL",
    unicode: "U+1F1F3 U+1F1F1",
    name: "Netherlands",
    emoji: "🇳🇱",
  },
  {
    code: "NO",
    unicode: "U+1F1F3 U+1F1F4",
    name: "Norway",
    emoji: "🇳🇴",
  },
  {
    code: "NP",
    unicode: "U+1F1F3 U+1F1F5",
    name: "Nepal",
    emoji: "🇳🇵",
  },
  {
    code: "NR",
    unicode: "U+1F1F3 U+1F1F7",
    name: "Nauru",
    emoji: "🇳🇷",
  },
  {
    code: "NU",
    unicode: "U+1F1F3 U+1F1FA",
    name: "Niue",
    emoji: "🇳🇺",
  },
  {
    code: "NZ",
    unicode: "U+1F1F3 U+1F1FF",
    name: "New Zealand",
    emoji: "🇳🇿",
  },
  {
    code: "OM",
    unicode: "U+1F1F4 U+1F1F2",
    name: "Oman",
    emoji: "🇴🇲",
  },
  {
    code: "PA",
    unicode: "U+1F1F5 U+1F1E6",
    name: "Panama",
    emoji: "🇵🇦",
  },
  {
    code: "PE",
    unicode: "U+1F1F5 U+1F1EA",
    name: "Peru",
    emoji: "🇵🇪",
  },
  {
    code: "PF",
    unicode: "U+1F1F5 U+1F1EB",
    name: "French Polynesia",
    emoji: "🇵🇫",
  },
  {
    code: "PG",
    unicode: "U+1F1F5 U+1F1EC",
    name: "Papua New Guinea",
    emoji: "🇵🇬",
  },
  {
    code: "PH",
    unicode: "U+1F1F5 U+1F1ED",
    name: "Philippines",
    emoji: "🇵🇭",
  },
  {
    code: "PK",
    unicode: "U+1F1F5 U+1F1F0",
    name: "Pakistan",
    emoji: "🇵🇰",
  },
  {
    code: "PL",
    unicode: "U+1F1F5 U+1F1F1",
    name: "Poland",
    emoji: "🇵🇱",
  },
  {
    code: "PM",
    unicode: "U+1F1F5 U+1F1F2",
    name: "St. Pierre and Miquelon",
    emoji: "🇵🇲",
  },
  {
    code: "PN",
    unicode: "U+1F1F5 U+1F1F3",
    name: "Pitcairn Islands",
    emoji: "🇵🇳",
  },
  {
    code: "PR",
    unicode: "U+1F1F5 U+1F1F7",
    name: "Puerto Rico",
    emoji: "🇵🇷",
  },
  {
    code: "PS",
    unicode: "U+1F1F5 U+1F1F8",
    name: "Palestinian Territories",
    emoji: "🇵🇸",
  },
  {
    code: "PT",
    unicode: "U+1F1F5 U+1F1F9",
    name: "Portugal",
    emoji: "🇵🇹",
  },
  {
    code: "PW",
    unicode: "U+1F1F5 U+1F1FC",
    name: "Palau",
    emoji: "🇵🇼",
  },
  {
    code: "PY",
    unicode: "U+1F1F5 U+1F1FE",
    name: "Paraguay",
    emoji: "🇵🇾",
  },
  {
    code: "QA",
    unicode: "U+1F1F6 U+1F1E6",
    name: "Qatar",
    emoji: "🇶🇦",
  },
  {
    code: "RE",
    unicode: "U+1F1F7 U+1F1EA",
    name: "Réunion",
    emoji: "🇷🇪",
  },
  {
    code: "RO",
    unicode: "U+1F1F7 U+1F1F4",
    name: "Romania",
    emoji: "🇷🇴",
  },
  {
    code: "RS",
    unicode: "U+1F1F7 U+1F1F8",
    name: "Serbia",
    emoji: "🇷🇸",
  },
  {
    code: "RU",
    unicode: "U+1F1F7 U+1F1FA",
    name: "Russia",
    emoji: "🇷🇺",
  },
  {
    code: "RW",
    unicode: "U+1F1F7 U+1F1FC",
    name: "Rwanda",
    emoji: "🇷🇼",
  },
  {
    code: "SA",
    unicode: "U+1F1F8 U+1F1E6",
    name: "Saudi Arabia",
    emoji: "🇸🇦",
  },
  {
    code: "SB",
    unicode: "U+1F1F8 U+1F1E7",
    name: "Solomon Islands",
    emoji: "🇸🇧",
  },
  {
    code: "SC",
    unicode: "U+1F1F8 U+1F1E8",
    name: "Seychelles",
    emoji: "🇸🇨",
  },
  {
    code: "SD",
    unicode: "U+1F1F8 U+1F1E9",
    name: "Sudan",
    emoji: "🇸🇩",
  },
  {
    code: "SE",
    unicode: "U+1F1F8 U+1F1EA",
    name: "Sweden",
    emoji: "🇸🇪",
  },
  {
    code: "SG",
    unicode: "U+1F1F8 U+1F1EC",
    name: "Singapore",
    emoji: "🇸🇬",
  },
  {
    code: "SH",
    unicode: "U+1F1F8 U+1F1ED",
    name: "St. Helena",
    emoji: "🇸🇭",
  },
  {
    code: "SI",
    unicode: "U+1F1F8 U+1F1EE",
    name: "Slovenia",
    emoji: "🇸🇮",
  },
  {
    code: "SJ",
    unicode: "U+1F1F8 U+1F1EF",
    name: "Svalbard and Jan Mayen",
    emoji: "🇸🇯",
  },
  {
    code: "SK",
    unicode: "U+1F1F8 U+1F1F0",
    name: "Slovak Republic",
    emoji: "🇸🇰",
  },
  {
    code: "SL",
    unicode: "U+1F1F8 U+1F1F1",
    name: "Sierra Leone",
    emoji: "🇸🇱",
  },
  {
    code: "SM",
    unicode: "U+1F1F8 U+1F1F2",
    name: "San Marino",
    emoji: "🇸🇲",
  },
  {
    code: "SN",
    unicode: "U+1F1F8 U+1F1F3",
    name: "Senegal",
    emoji: "🇸🇳",
  },
  {
    code: "SO",
    unicode: "U+1F1F8 U+1F1F4",
    name: "Somalia",
    emoji: "🇸🇴",
  },
  {
    code: "SR",
    unicode: "U+1F1F8 U+1F1F7",
    name: "Suriname",
    emoji: "🇸🇷",
  },
  {
    code: "SS",
    unicode: "U+1F1F8 U+1F1F8",
    name: "South Sudan",
    emoji: "🇸🇸",
  },
  {
    code: "ST",
    unicode: "U+1F1F8 U+1F1F9",
    name: "São Tomé and Príncipe",
    emoji: "🇸🇹",
  },
  {
    code: "SV",
    unicode: "U+1F1F8 U+1F1FB",
    name: "El Salvador",
    emoji: "🇸🇻",
  },
  {
    code: "SX",
    unicode: "U+1F1F8 U+1F1FD",
    name: "Sint Maarten",
    emoji: "🇸🇽",
  },
  {
    code: "SY",
    unicode: "U+1F1F8 U+1F1FE",
    name: "Syria",
    emoji: "🇸🇾",
  },
  {
    code: "SZ",
    unicode: "U+1F1F8 U+1F1FF",
    name: "Swaziland",
    emoji: "🇸🇿",
  },
  {
    code: "TA",
    unicode: "U+1F1F9 U+1F1E6",
    name: "Tristan da Cunha",
    emoji: "🇹🇦",
  },
  {
    code: "TC",
    unicode: "U+1F1F9 U+1F1E8",
    name: "Turks and Caicos Islands",
    emoji: "🇹🇨",
  },
  {
    code: "TD",
    unicode: "U+1F1F9 U+1F1E9",
    name: "Chad",
    emoji: "🇹🇩",
  },
  {
    code: "TF",
    unicode: "U+1F1F9 U+1F1EB",
    name: "French Southern Territories",
    emoji: "🇹🇫",
  },
  {
    code: "TG",
    unicode: "U+1F1F9 U+1F1EC",
    name: "Togo",
    emoji: "🇹🇬",
  },
  {
    code: "TH",
    unicode: "U+1F1F9 U+1F1ED",
    name: "Thailand",
    emoji: "🇹🇭",
  },
  {
    code: "TJ",
    unicode: "U+1F1F9 U+1F1EF",
    name: "Tajikistan",
    emoji: "🇹🇯",
  },
  {
    code: "TK",
    unicode: "U+1F1F9 U+1F1F0",
    name: "Tokelau",
    emoji: "🇹🇰",
  },
  {
    code: "TL",
    unicode: "U+1F1F9 U+1F1F1",
    name: "Timor-Leste",
    emoji: "🇹🇱",
  },
  {
    code: "TM",
    unicode: "U+1F1F9 U+1F1F2",
    name: "Turkmenistan",
    emoji: "🇹🇲",
  },
  {
    code: "TN",
    unicode: "U+1F1F9 U+1F1F3",
    name: "Tunisia",
    emoji: "🇹🇳",
  },
  {
    code: "TO",
    unicode: "U+1F1F9 U+1F1F4",
    name: "Tonga",
    emoji: "🇹🇴",
  },
  {
    code: "TR",
    unicode: "U+1F1F9 U+1F1F7",
    name: "Turkey",
    emoji: "🇹🇷",
  },
  {
    code: "TT",
    unicode: "U+1F1F9 U+1F1F9",
    name: "Trinidad and Tobago",
    emoji: "🇹🇹",
  },
  {
    code: "TV",
    unicode: "U+1F1F9 U+1F1FB",
    name: "Tuvalu",
    emoji: "🇹🇻",
  },
  {
    code: "TW",
    unicode: "U+1F1F9 U+1F1FC",
    name: "Taiwan",
    emoji: "🇹🇼",
  },
  {
    code: "TZ",
    unicode: "U+1F1F9 U+1F1FF",
    name: "Tanzania",
    emoji: "🇹🇿",
  },
  {
    code: "UA",
    unicode: "U+1F1FA U+1F1E6",
    name: "Ukraine",
    emoji: "🇺🇦",
  },
  {
    code: "UG",
    unicode: "U+1F1FA U+1F1EC",
    name: "Uganda",
    emoji: "🇺🇬",
  },
  {
    code: "UM",
    unicode: "U+1F1FA U+1F1F2",
    name: "U.S. Outlying Islands",
    emoji: "🇺🇲",
  },
  {
    code: "UN",
    unicode: "U+1F1FA U+1F1F3",
    name: "United Nations",
    emoji: "🇺🇳",
  },
  {
    code: "US",
    unicode: "U+1F1FA U+1F1F8",
    name: "USA",
    emoji: "🇺🇸",
  },
  {
    code: "UY",
    unicode: "U+1F1FA U+1F1FE",
    name: "Uruguay",
    emoji: "🇺🇾",
  },
  {
    code: "UZ",
    unicode: "U+1F1FA U+1F1FF",
    name: "Uzbekistan",
    emoji: "🇺🇿",
  },
  {
    code: "VA",
    unicode: "U+1F1FB U+1F1E6",
    name: "Vatican City",
    emoji: "🇻🇦",
  },
  {
    code: "VC",
    unicode: "U+1F1FB U+1F1E8",
    name: "St. Vincent and Grenadines",
    emoji: "🇻🇨",
  },
  {
    code: "VE",
    unicode: "U+1F1FB U+1F1EA",
    name: "Venezuela",
    emoji: "🇻🇪",
  },
  {
    code: "VG",
    unicode: "U+1F1FB U+1F1EC",
    name: "British Virgin Islands",
    emoji: "🇻🇬",
  },
  {
    code: "VI",
    unicode: "U+1F1FB U+1F1EE",
    name: "Virgin Islands (U.S.)",
    emoji: "🇻🇮",
  },
  {
    code: "VN",
    unicode: "U+1F1FB U+1F1F3",
    name: "Vietnam",
    emoji: "🇻🇳",
  },
  {
    code: "VU",
    unicode: "U+1F1FB U+1F1FA",
    name: "Vanuatu",
    emoji: "🇻🇺",
  },
  {
    code: "WF",
    unicode: "U+1F1FC U+1F1EB",
    name: "Wallis and Futuna",
    emoji: "🇼🇫",
  },
  {
    code: "WS",
    unicode: "U+1F1FC U+1F1F8",
    name: "Samoa",
    emoji: "🇼🇸",
  },
  {
    code: "XK",
    unicode: "U+1F1FD U+1F1F0",
    name: "Kosovo",
    emoji: "🇽🇰",
  },
  {
    code: "YE",
    unicode: "U+1F1FE U+1F1EA",
    name: "Yemen",
    emoji: "🇾🇪",
  },
  {
    code: "YT",
    unicode: "U+1F1FE U+1F1F9",
    name: "Mayotte",
    emoji: "🇾🇹",
  },
  {
    code: "ZA",
    unicode: "U+1F1FF U+1F1E6",
    name: "South Africa",
    emoji: "🇿🇦",
  },
  {
    code: "ZM",
    unicode: "U+1F1FF U+1F1F2",
    name: "Zambia",
    emoji: "🇿🇲",
  },
  {
    code: "ZW",
    unicode: "U+1F1FF U+1F1FC",
    name: "Zimbabwe",
    emoji: "🇿🇼",
  },
];
